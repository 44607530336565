.list {
    list-style: none;
}

.space {
    height: 600px;
    
}

.tableFixHead {
    overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
    height: 250px; /* gives an initial height of 200px to the table */
    
    border-radius: 5px;
   
  }
  .tableFixHead thead th {
    position: sticky; /* make the table heads sticky */
    top: 0px; /* table head will be placed from the top of the table and sticks to it */
    background-color: lightgrey;
    padding: auto;
    
}

  table {
    border-collapse: collapse; /* make the table borders collapse to each other */
    width: 100%;
  }
  th,
  td {
    background-color: white;
    padding: 8px 16px;
  }
  th {
    background: #eee;
  }

.m-title{
    border-radius: 20px 0px 20px 0px;
    height: max-content;
    color: white;
    width: fit-content;
    background-color: #060b26;
}