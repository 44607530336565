.navbar {
    background-color: #060b26;
    height: 80px;
    width: 100%;
    z-index: 2;
}



.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: #060b26;
    width: 260px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 2;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
    z-index: 2;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}
.nav-title{
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 12px;
    border-radius: 4px;
}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.nav-text a:hover {
    background-color: #1a83ff;
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

span {
    margin-left: 16px;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

div .accordion{
    background-color: #060b26 !important;
    color: #060b26;
}
 .nav-text-2 {
    padding-left: 0%;
    margin-left: 0%;
 }

 .accordion-button.collapsed {
    background: #060b26;
  }
  
  .accordion-button:not(.collapsed) {
    background: #060b26;
  }
  .accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

