body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chart-wrapper{
  overflow-x: scroll;
}

.bg-deepblue{
  background: #060b26;
}

.infoDataSection {
  height: 300px;
}

.title-image{
  width: auto;
  height: 300px;
  padding-bottom: 0%;
}

.footer{
  position:absolute;
left:0;
bottom:0;
right:0;
}

.footer-container{
  padding-bottom:60px;
}