.status{
    width: 100%;
    text-align: center;
    border-radius: 20px;
}
.collect{
    background-color: skyblue;
    color: white;
}
.process{
    background-color: aquamarine;
    color: black;
}
.done{
    background-color: green;
    color: aliceblue;
}
.predict{
    background-color: seagreen;
    color: white;
}
.error{
    background-color: red;
    color: white;
}